<template>
    <section class="moduloDontMiss">
        <div class="cont">
            <OggettoHeadModulo :modulo="modulo" />
            <div class="grid">
                <div class="gridItem" v-for="(card, index) in modulo.card">
                    <OggettoGenericoCard :card="card" :typename="card.item?.__typename || card.collection" :width="calculateGridWidth(index)" :height="300" :mobileHeight="600" />
                </div>
                <div class="searchItem">
                    <div>
                        <div class="tagBox mini">{{ $t('cerca') }}</div>
                    </div>
                    <div class="title4">{{ $t('dontmiss_descrizione') }}</div>
                    <div class="searchbox">
                        <NForm @submit.prevent="submitSearch">
                            <NInput v-model:value="data.search" size="large" round :placeholder="$t('dontmiss_input')" :input-props="{ tabindex: -1, 'aria-label': $t('cerca') }">
                                <template #suffix>
                                    <NIcon size="1.412rem" :component="SearchRound" aria-hidden="true" />
                                </template>
                            </NInput>
                        </NForm>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script lang="ts" setup>
import { NForm, NInput, NIcon } from 'naive-ui'
import { SearchRound } from '@vicons/material'
const localeRoute = useLocaleRoute()

defineProps<{ modulo: any }>()

const data = reactive({
    search: undefined
})

const calculateGridWidth = (index: number) => {
    if (index == 0) return 716
    else if (index == 1 || index == 2) return 474
    else return 353

}

const submitSearch = async () => {
    if (data.search) {
        await navigateTo(localeRoute('/cerca?text=' + data.search))
    }
}
</script>

<style lang="scss" scoped>
.moduloDontMiss {

    .cont {
        display: flex;
        flex-direction: column;
        gap: var(--article-gap-inner);
    }

    .innerCont {
        display: flex;
        flex-direction: column;
        gap: var(--margin-default);

        .head {
            display: flex;
            flex-direction: row;
            gap: var(--margin-double);

            .linkMobile {
                display: none;
            }
        }
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(10, minmax(0, 1fr));
        gap: var(--margin-gap);

        grid-template-areas:
            "a a a a a a b b b b"
            "c c c c d d d e e e";

        .gridItem {
            position: relative;

            &:nth-of-type(1) {
                grid-area: a;
                height: 300px;
            }

            &:nth-of-type(2) {
                grid-area: b;
                height: 300px;
            }

            &:nth-of-type(3) {
                grid-area: c;
                height: 300px;
            }

            &:nth-of-type(4) {
                grid-area: d;
                height: 300px;
            }
        }

        .searchItem {
            grid-area: e;
            height: 300px;

            background-color: var(--color-azure-back);
            color: var(--color-text-contrast);
            border-radius: var(--size-card-radius);
            overflow: hidden;

            padding: var(--margin-default);
            box-sizing: border-box;

            display: grid;
            grid-template-rows: min-content 1fr min-content;
            gap: var(--margin-gap);

            .tagBox {
                display: inline-block;
                background-color: var(--color-azure-dark);

                padding: var(--margin-mini) var(--margin-gap);
                border-radius: calc((var(--margin-mini) * 2 + var(--font-line-mini)) / 2);

                color: var(--color-text-contrast);
            }

            &:deep(.n-input) {
                --n-border: none;
                --n-border-hover: none;
                --n-border-focus: none;

                --n-color: rgba(255, 255, 255, 0.20);
                --n-color-focus: rgba(255, 255, 255, 0.30);
                --n-text-color: white;
            }
        }
    }
}

@media screen and (max-width: 860px) {
    .moduloDontMiss {
        .grid {
            grid-template-areas:
                "a a a a a b b b b b"
                "c c c c c c c c c c"
                "d d d d d d e e e e";
        }
    }
}

@media screen and (max-width: 600px) {
    .moduloDontMiss {
        .grid {
            display: flex;
            flex-direction: column;

            .gridItem {
                height: 300px !important;
            }

            .searchItem {
                height: 250px;
            }
        }
    }
}
</style>